.Rectangle {
	background-color: #3d3d3d;
	font-family: 'Roboto', sans-serif;
	padding-top: 1rem;
	overflow: hidden;
}

.sendMessage{
  border: none !important;
  background-color: white;
  padding-top: 1.5rem;
  padding-right: 2.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2.25rem;
}

.black{
	color:black;
}

.red{
	color:red;
}

.list-group{
	padding-left: 1.25rem;
	border: none !important;
}

.SEND-ALERT-MESSAGE {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 15.6px;
  font-weight: bold;
  color: #4d138c;
}

.FACILITY {
  width: 100%;
  height: 14px;
  font-family: 'Roboto', sans-serif;
  font-size: 11.7px;
  font-weight: 600;
  color: #500a90 !important;
}

.SELECT-ALERT-MESSAGE {
  width: 100%;
  height: 14px;
  font-family: 'Roboto', sans-serif;
  font-size: 11.7px;
  font-weight: 600;
  color: #500a90 !important;
}

.mb-3 {
	margin-bottom: 18vh !important;
}

.Dropdown-selection {
  width: 97%;
  height: 52px;
  border-radius: 1.6px;
  border: solid 2px #412c91;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14.6px;
}

.card-footer{
	border: none !important;
	background-color: white;
}

.notification-btn {
  width: 97%;
	min-height: 7vh;
	background-color: #412c91 !important;
	color: white;
	font-family: 'Roboto', sans-serif;
}


.Rectangle .modal-dialog  { 
  width: 40%;
  font-family: 'Roboto', sans-serif;
}

.Rectangle .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
}

.Rectangle .modal-title {
  width: 175px;
  height: 18px;
  margin: 0 67px 46px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 20.6px;
  font-weight: 2500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #540094;
}

.CANCEL {
  width: 20%;
  height: 6vh;
  font-family: 'Roboto', sans-serif;
}

.SEND {
  width: 20%;
  height: 6vh;
  margin: 2vh 10vh 2vh 30vh;
  font-family: 'Roboto', sans-serif;
}

.ALERT-STATUS {
  height: 14px;
  font-family: 'Roboto', sans-serif;
  font-size: 11.7px;
  font-weight: bold;
  color: #0d0d0d;
}

.nomessage {
	margin-top: 30%;
	margin-bottom: 10.5%;
}

.NO-MESSAGE-SENT {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #383838;
	font-size: 15.6px;
	border: none !important;
}

.failure{
  height: 6vh;
  margin-top: 2vh;
  margin-left: 2vh;
  font-family: 'Roboto', sans-serif;
}

.errorModal{
	background-color: red !important;
	height: 18vh;
	font-family: 'Roboto', sans-serif;
}

.errorMessage {
    margin: 2vh 0 0 3vh;
	font-family: 'Roboto', sans-serif;
	font-size: 22.6px;
	font-weight: 2500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.errorheader {
    margin: -38px 0 0 10vh;
	font-family: 'Roboto', sans-serif;
	font-size: 22.6px;
	font-weight: 2500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.h-100 {
	height: 82vh !important;
}

.message-modal .uppercase {
  text-transform: uppercase;
}

.message-modal .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
  color: #4d138c;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
  border-bottom: none !important;
}

.message-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.message-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.message-modal .modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}

.message-modal .modal-footer span {
  flex-grow: 1;
}
