.Container .modal-dialog  { 
  font-family: 'Roboto', sans-serif;
}

.container-fluid {
  display: flex;
  flex-direction: column;
}

.facility-container {
  /* --primary-color: #540094;  */
  --primary-color: #4d138c; 
  --title-font-size: 1rem;
  border: none !important;
  background-color: white;
  padding: 0.5rem 0rem 0.25rem 0rem;
}

.facility-title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: var(--title-font-size);
  font-weight: bold;
  color: var(--primary-color);
}
  
.facility-container .search-row {
  padding: 0.75rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facility-container .search-row span {
  flex-grow: 1;
}

.facility-container .add-button {
  background-color: green;
  height: 36px;
  border: none;
  width: 140px;
  color: white;
  font-size: 0.85rem;
  cursor: pointer;
}

.facility-container .search-row .search-button::after {
  content: "";
  clear: both;
  display: table;
}

.facility-container .card-header {
  padding: 0.75rem 2rem;
  position: sticky;
  z-index: 1;
  background: white;
}

.table-container {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.table-container .table {
  display: block;
  width: 100%;
  color: black;
}

.table-container .table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-container .table tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.table-container .react-bootstrap-table {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.table-container .react-bootstrap-table th {
  width: 10%; 
}

.table-container .react-bootstrap-table th:first-child, 
.table-container .react-bootstrap-table td:first-child {
  padding-left: 2rem !important;
}

.table-container .react-bootstrap-table th:last-child, 
.table-container .react-bootstrap-table td:last-child {
  padding-right: 2rem !important;
}

.table-container .react-bootstrap-table .thead-light th {
  background-color: white;
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.table-container .react-bootstrap-table .device-count {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: #007bff;
  display: inline;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.table-container .react-bootstrap-table .table-actions {
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
}

.table-container .react-bootstrap-table .table-actions .delete-button,
.table-container .react-bootstrap-table .table-actions .edit-button {
  background-color: white;
  border: none;
  cursor: pointer;
}

.table-container .react-bootstrap-table .table-actions .delete-button i,
.table-container .react-bootstrap-table .table-actions .edit-button i {
  color: #777;
}

/* Devices */
.device-list-modal .modal-dialog {
  max-width: 600px;
  max-height: 600px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.device-list-modal .modal-content {
  width: 600px;
  height: 600px;
}

.device-list-modal .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
  color: #4d138c;
  padding: 1rem 0.75rem 0.75rem 0.75rem;
  border-bottom: none;
  position: sticky;
  z-index: 1;
}

.device-list-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.device-list-modal .device-updated {
  color: black;
  font-size: 0.8rem;
  position: relative;
  top: -14px;
  left: 0.75rem;
  width: 100%;
}

.device-list-modal .device-list-exit {
  font-size: 1rem;
  font-weight: bold;
  height: 18px;
  margin-bottom: 0;
  line-height: 1;
  cursor: pointer;
}

.device-list-modal .modal-body {
  padding: 0rem;
  overflow-y: auto;
}

.devices-table {
  height: 100%;
  position: relative;
  overflow: auto;
}

.devices-table .table {
  flex: 1;
  overflow-y: auto;
}

.devices-table .table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.devices-table .table .thead-light th {
  background-color: white;
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.delete-facility-modal {
}

.delete-facility-modal .uppercase {
  text-transform: uppercase;
}

.delete-facility-modal .modal-header {
  height: 50px;
  font-family: 'Roboto', sans-serif;
  color: #aa0000;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
  border-bottom: none;
}

.delete-facility-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aa0000;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.delete-facility-modal .modal-content p {
  margin-bottom: 0;
}

.delete-facility-modal .modal-content p.delete-main {
  font-weight: 700;
}

.delete-facility-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
}

.delete-facility-modal .modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}

.delete-facility-modal .modal-footer span {
  flex-grow: 1;
}

.delete-facility-modal .modal-footer .btn {  
  background-color: #777;
  height: 36px;
  border: none;
  width: 200px;
  color: white;
  font-size: 0.85rem;
  cursor: pointer;
}

.delete-facility-modal .modal-footer .btn:nth-of-type(2) {  
  background-color: #aa0000;
}

.delete-facility-error-modal .modal-dialog {
  /* max-width: 700px;
  max-height: 700px; */
}

.delete-facility-error-modal .uppercase {
  text-transform: uppercase;
}

.delete-facility-error-modal .modal-header {
  height: 50px;
  font-family: 'Roboto', sans-serif;
  color: #aa0000;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
}

.delete-facility-error-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aa0000;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.delete-facility-error-modal .modal-content p {
  margin-bottom: 0;
}

.delete-facility-error-modal .modal-content p.error-main {
  color: #aa0000;
}

.delete-facility-error-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.delete-facility-error-modal .modal-footer .btn {  
  background-color: #4d138c;
  height: 36px;
  border: none;
  width: 200px;
  color: white;
  font-size: 0.85rem;
  cursor: pointer;
}


/* Toast */
.toast-container {
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  color: #fff;
}

.toast-add {
  background-color: #28a745;
}

.toast-delete {
  background-color: #aa0000;
}


.loading {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
  align-items: center;
  color: #adadad;
}