.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  background-color: #4d138c;
  font-family: 'Roboto', sans-serif;
}

.logo, .auth {
  width: 114px;
  height: 33px;
  font-family: 'Roboto', sans-serif;
}

.header-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.header-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  flex-grow: 1;
}

.header-links li {
  margin-left: 40px;
}

.header-links li:first-child {
  margin-left: 0px;
}

.header-link {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.7;
  font-size: 17px;
  font-weight: 700;
}

.header-link:first-child {
  margin-left: 0;
}

.header-link:hover {
  color:#ffffff;
  text-decoration: none;
  opacity: 0.9;
}

.active-link {
  position: relative;
  opacity: 1;
}

.active-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 3px;
  background-color:  #ffffff;
}