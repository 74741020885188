.facility-modal .uppercase {
  text-transform: uppercase;
}

.facility-modal .modal-dialog {
  max-width: 700px;
  max-height: 700px;
}

.facility-modal .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
  color: #4d138c;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
  border-bottom: none !important;
}

.facility-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.facility-modal .modal-body {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.facility-modal .modal-body .form-container {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.facility-modal .modal-body .section {
  margin-top: 10px;
  padding-bottom: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #ddd;
}

.facility-modal .modal-body h2 {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

.facility-modal .modal-body .section .row {
  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;
  margin-bottom: 0.8rem;
}

.facility-modal .modal-body .section .col {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.facility-modal .modal-body .section .col-divided {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.facility-modal .modal-body .section .col-divided:nth-child(1) {
  width: 45%;
}

.facility-modal .modal-body .section .col-divided:nth-child(2) {
  width: 45%;
}

.facility-modal .modal-body .section .col .input-container .error-text {
  position: absolute;
  top: 100%;
  left: 0;
  color: #aa0000;
  opacity: 0;
  transform: translateX(15px);
  font-size: 0.7rem;
  transition: opacity 0.3s ease;
}

.facility-modal .modal-body .section .col .input-container .visible {
  opacity: 1;
}

.facility-modal .modal-body .section .col-divided .input-divided .error-text {
  left: 0;
}

.facility-modal .modal-body .section .col-divided .input-divided:nth-child(2) .error-text {
  left: 50%;
}

.facility-modal .modal-body .input-grow{
  flex-grow: 1;
}

.facility-modal .modal-body label {
  display: block;
  color: purple;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

.facility-modal .modal-body input,
.facility-modal .modal-body select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0;
  flex-grow: 1;
}

.facility-modal .modal-body .input-container input[type=text] {
  background-color: #fff; 
  font-size: 0.9rem;
  color: #333;
}

.facility-modal .modal-body .input-container select {
  background-color: #fff; 
  font-size: 0.9rem;
  color: #333;
}

.facility-modal .modal-body .input-container .custom-btn {
  background-color: white;
  border: none;
  width: 100%;
  color: blue;
  font-size: 0.65rem;
  font-weight: 500;
  text-align: right;
  cursor: pointer;
}

.facility-modal .modal-footer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.facility-modal .modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}

.facility-modal .modal-footer span {
  flex-grow: 1;
}

.facility-error-modal .modal-dialog {
  /* max-width: 700px;
  max-height: 700px; */
}

.facility-error-modal .uppercase {
  text-transform: uppercase;
}

.facility-error-modal .modal-header {
  height: 50px;
  font-family: 'Roboto', sans-serif;
  color: #aa0000;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
}

.facility-error-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aa0000;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.facility-error-modal .modal-content p {
  margin-bottom: 0;
}

.facility-error-modal .modal-content p.error-main {
  color: #aa0000;
}

.facility-error-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.facility-error-modal .modal-footer .btn {  
  background-color: #4d138c;
  height: 36px;
  border: none;
  width: 200px;
  color: white;
  font-size: 0.85rem;
  cursor: pointer;
}

.ip-assignment-modal .uppercase {
  text-transform: uppercase;
}

.ip-assignment-modal .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
  color: #4d138c;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
  border-bottom: none !important;
}

.ip-assignment-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.ip-assignment-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.ip-assignment-modal .modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}

.ip-assignment-modal .modal-footer span {
  flex-grow: 1;
}

.custom-field-modal .uppercase {
  text-transform: uppercase;
}

.custom-field-modal .modal-header {
	height: 50px;
	font-family: 'Roboto', sans-serif;
  color: #4d138c;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  z-index: 1;
  border-bottom: none !important;
}

.custom-field-modal .modal-title {
  width: 100%;
  height: 18px;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.custom-field-modal .modal-body input { 
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0;
  flex-grow: 1;
}

.facility-modal .modal-body .input-container input[type=text] {
  background-color: #fff; 
  font-size: 0.9rem;
  color: #333;
}

.custom-field-modal .modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.custom-field-modal .modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}

.custom-field-modal .modal-footer span {
  flex-grow: 1;
}

