.main{
	overflow: hidden;
	background-color: #3d3d3d;
	width: 100%;
	position: relative;
	font-weight: 500;
	margin: 0 auto;
	min-height: 100vh;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
}

.Container {
	background-color: #3d3d3d;
	font-family: 'Roboto', sans-serif;
	padding-top: 1rem;
	overflow: auto;
}
