.Container .modal-dialog  { 
  font-family: 'Roboto', sans-serif;
}

.container-fluid {
  display: flex;
  flex-direction: column;
}

.report-container {
  --primary-color: #4d138c; 
  --title-font-size: 1rem;
  border: none !important;
  background-color: white;
  padding: 0.5rem 0rem 0.25rem 0rem;
}
  
.report-container .search-row {
  padding: 0.75rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.report-container .search-row span {
  flex-grow: 1;
}

.report-container .search-row .search-button::after {
  content: "";
  clear: both;
  display: table;
}

.report-container .card-header {
  padding: 0.75rem 2rem;
  position: sticky;
  z-index: 1;
  background: white;
  display: flex;
}

.report-container .card-header .report-title {
  width: 33%;
  font-family: 'Roboto', sans-serif;
  font-size: var(--title-font-size);
  font-weight: bold;
  color: var(--primary-color);
}

.report-container .card-header .btn-group {
  width: 33%;
  font-family: 'Roboto', sans-serif;
}

.report-container .card-header .btn-group .btn {
  font-size: 12px;
  background-color: white;
  color: black;
  border-radius: 0;
}

.report-container .card-header .btn-group .active {
  font-size: 12px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 0;
}

.table-container {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.table-container .table {
  display: block;
  width: 100%;
  color: black;
}

.table-container .table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-container .table tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.table-container .react-bootstrap-table {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.table-container .react-bootstrap-table th {
  width: 10%; 
}

.table-container .react-bootstrap-table th:first-child, 
.table-container .react-bootstrap-table td:first-child {
  padding-left: 2rem !important;
}

.table-container .react-bootstrap-table th:last-child, 
.table-container .react-bootstrap-table td:last-child {
  padding-right: 2rem !important;
}

.table-container .react-bootstrap-table .thead-light th {
  background-color: white;
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
  align-items: center;
  color: #adadad;
}