.Button-clear{
	width: 100%;
	height: 7vh;
	background-color: #412c91 !important;
	color: white;
	font-family: 'Roboto', sans-serif;
}

.collpaseClear{
  max-width: 6rem;
  max-height: 3rem;
  background-color: #ffffff !important;
  color: #412c91 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 8.7px;
  font-weight: 500;
}

.clear-modal-footer {
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.clear-modal-footer span {
  flex-grow: 1;
}

.clear-modal-footer .btn {  
  flex-basis: 40%;
  border-radius: 0;
}
