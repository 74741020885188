.search-row select {
  background-color: #fff; 
  width: 140px;
  height: 35px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  color: #333;
  padding-left: 5px;
}

.search-row input[type=text] {
  background-color: #fff; 
  width: 260px;
  height: 35px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  color: #333;
  padding-left: 5px;
}

.search-row .clear-button {
  position: relative;
  right: 10px;
  top: 25%;
  transform: translate(-75%, -50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-row .clear-button.visible {
  opacity: 1;
}

.search-row .search-button,
.search-row .clear-search-button  {
  background-color: var(--primary-color);
  height: 36px;
  border: none;
  width: 70px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.search-row .search-button:hover,
.search-row .clear-search-button:hover {
  background: #340d5f;
}

.search-row .search-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-row span.search-string {
  color: var(--primary-color);
  margin-left: 20px;
}