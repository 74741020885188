.not-found-container {
  border: none !important;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  padding: 1.5rem 2.25rem 0.25rem 2.25rem;
}

.not-found-container h2 {
  font-size: 88px;
  font-weight: bold;
  color: #4d138c;
  margin: 0;
  line-height: 1.0;
}

.not-found-container h3 {
  font-weight: bold;
  color: #4d138c;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.0;
}

.not-found-container p {
  margin-bottom: 1.1rem;
  margin-top: 1.1rem;
}

.not-found-btn {
  border: none;
  border-radius: 0;
  width: 100%;
  min-height: 6vh;
  background-color: #412c91 !important;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  padding: 0.375rem 0.5rem;
}

