.react-bootstrap-table{
    height: 10.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

.SelectedMessage{
  width: 100%;
  height: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff !important;
  margin-top: 1.25rem !important;
  margin-left: -3rem !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.Messages {
  width: 100%;
  height: 22px;
  font-family: 'Roboto', sans-serif;
  font-size: 18.2px;
  font-weight: bold;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 0.5rem;
  margin-left: -0.5rem;
}

.card-header{
    border: none !important;
}

.emergencyMessage {
  height: 22px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.emergencyMessageCollapsed{
  width: 100%;
  height: 22px;
  font-family: 'Roboto', sans-serif;
  font-size: 18.2px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 8px;
  margin-top: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.message {
  height: 22px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff !important;
  margin-top: 2.25rem !important;
  margin-left: -3rem !important;
}

.facilityMessage {
  height: 28px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff !important;
  margin-top: 1.5rem !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
}

.alert{
  height: 60px;
  margin-left: -1rem;
  margin-top: -0.5rem;
}
.mr-3{
    margin-top: 0.625rem;
    margin-left: -2rem;
}

.Emergency {
    margin-left: -2rem;
    margin-top: -0.625rem;
}

.clear_section{
    margin-left: 17rem;
    margin-top: -5.5rem;
}

.emergencyalert{
  height: 56px;
}

.Message-Sent {
  width: 100%;
  margin-right: -20px; 
  background-color: #008a28;
  font-family: 'Roboto', sans-serif;
}

.CountMessage{
    width: 100%;
    height: 18px;
    margin: 0 0 50px 5vh;
    font-family: 'Roboto', sans-serif;
    font-size: 15.6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}


.RECEIVED {
  width: 100%;
  height: 20px;
  margin-left: 2vh;
  font-family: 'Roboto', sans-serif;
  font-size: 15.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d138c;
  margin-top: 1rem;
}

.Collapsedata {
  font-size: 13.2px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff !important;
  margin-left: 1.85rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.collapseToggle {
  font-family: 'Roboto', sans-serif;
  font-size: 13.2px;
  font-weight: bold;
  color: #ffffff !important;
  margin-left: 3rem;
  margin-bottom: 0.3rem;
}

.collapseToggleExpand {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  max-height: 28px;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.collapseToggleText {
  max-height: 10px;
  font-size: 13.2px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 1.5rem;
}

.loader {
    width: 100%;
    margin-top: 10vh;
    margin-left: 25vh;
    margin-bottom: 15vh;
    font-family: 'Roboto', sans-serif;
}

.fa {
    color: #ffffff;
}

.cleardiv{
    margin-top: -2rem;
}



